// Header Compoent
import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
  
// import from './header.css';

const Header = ({ siteTitle }) => {
  const [isOpen, setIsOpen] = useState(false); // setttingan untuk toggle

  const toggle = () => setIsOpen(!isOpen); // handle toogle event
  return (
    <>

      <Navbar light expand="sm">
        <div className="container">
          <NavbarBrand href="/">{siteTitle}</NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/">HOME</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/team">TIM</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/tags">LABEL</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/arsipfile">ARSIP FILE</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/about">TENTANG</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
