/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

/**
 * Layout component
 * Compoent ini akan digunakan oleh semua page karna compoent ini yang akan mengantur tata letak page 
 */

import React, {useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import '../styles/index.scss'
import Footer from './Footer'
import Typed from 'react-typed';

import AvatarInfoImg from '../images/cartoon-man-reading-book-sitting-stack-big-books-hot-coffee-mug-blue_18591-63800.jpg'
import AvatarTeamWork from '../images/people-grow-potted-plants-2x.jpg'

import { 
  Row, 
  Col,
  Card, 
  CardBody,
  CardTitle,
  Form,
  FormGroup 
} from 'reactstrap'


const Layout = ({authorImageFluid, postAuthor, children, pageTitle }) => { // props.pageTitle
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {  
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [subject, setSubject] = useState('Bergabung kedalam team Waingapu Open Mind');
  const [email, setEmail] = useState('');

  function handleOnSubmit(e){
    e.preventDefault();
    let body = `Dear Waingapu Open Mind From ${email}`
    window.location.href = `mailto:umburambu@gmail.com?subject=${subject}&body=${body}`;
  }

  return (
    <>
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
        integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/"
        crossOrigin="anonymous"
      />
      <Header siteTitle={data.site.siteMetadata.title} />
        <div>{children}</div>
        <div>
          <Card className="text-white mb-3 text-center" style={{backgroundColor: '#424242'}}>
              <CardBody>
              <Row>
                <Col md="8">
                  <Card style={{color: '#FFFFFF', backgroundColor: '#616161'}}>
                    <CardTitle> 
                      <h1>
                        <Typed
                          strings={[
                            'Semoga Artikel Disini Bermanfaat!',
                            ]}
                            typeSpeed={80}
                            backSpeed={80}
                            loop
                        />                      
                      </h1>
                       

                    </CardTitle>        
                    <CardBody>  
                        <p className="text-left">
                          Waingapu Open Mind adalah media informasi yang mewadahi argumen-argumen anak-anak waingapu terhadap isu-isu peristiwa yang telah, sedang, dan akan terjadi di dunia ini yang dituangkan kedalam suatu artikel.
                        </p>
                        <p className="text-left">
                          Menyampaikan informasi yang memiliki kredibilitas baik sangat menjunjung tinggi realita dari informasi, sehingga Waingapu Open Mind menjadi media informasi yang <span style={{fontWeight: 'bold'}}>SUMBER JELAS, INDEPENDEN, TRANSPARAN, APA ADANYA</span >
                        </p>
                        <p className="text-left">
                          Setiap artikel yang ada di Waingapu Open Mind merupakan hasil pemikiran yang ditulis oleh anak-anak Waingapu yang berpikir terbuka (open mind) mengenai isu-isu peristiwa yang telah, sedang dan akan terjadi di dunia ini.
                        </p>
                        <br/>
                        <div className="text-right">
                          <Typed
                            strings={[
                              'Sumber Jelas',
                              'Independen',
                              'Transparan',
                              'Apa adanya',
                              ]}
                              typeSpeed={80}
                              backSpeed={80}
                              loop
                          />                      
                        </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <img src={AvatarInfoImg} className="img-fluid" alt="AvatarImg" />
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className="text-white mb-3 text-center" style={{backgroundColor: '#E9ECEF'}}>
              <CardBody>
              <Row>
                <Col md="4" className="text-center">
                  <p className="lead text-left" style={{color: '#000000'}}>
                    Silakan masukan email anda dan hubungi kami untuk menjadi bagian dalam tim Waingapu Open Mind
                  </p>
                  <Form>
                    <FormGroup> 
                      <input type="email" name="email" placeholder="Masukan Emailmu..." style={{width: '100%'}} onChange={(e) => setEmail(e.target.value)}  />
                    </FormGroup>
                    <button className="btn text-uppercase btn-success" style={{width: '100%'}} onClick={handleOnSubmit}>Hubungi Kami</button>
                  </Form>
                </Col>
                <Col md="8" className="p-3">
                  <Row>
                    <Col md="4">
                      <img src={AvatarTeamWork} className="img-fluid" alt="AvatarImg" />
                    </Col>
                    <Col md="8">
                      <Card style={{color: '#000000'}}>
                          <CardTitle> 
                            <h1>
                              <Typed
                                strings={[
                                  'Ayo Gabung Bersama Kami!',
                                  ]}
                                  typeSpeed={80}
                                  backSpeed={80}
                                  loop
                              />                      
                            </h1>
                             

                          </CardTitle>        
                          <CardBody>  
                              <p className="text-left">
                                Waingapu Open Mind memuat artikel-artikel menarik yang merupakan hasil curahan terdapap peristiwa yang telah, sedang dan akan terjadi di dunia ini oleh anak-anak waingapu yang berpikir kristis dan terbuka dan berani untuk di publikasin didepan umum.
                              </p>
                              <p className="text-left">
                                Ayo gabung sekarang dan menjadi bagian dalam team Waingapu Open Mind, Jadikan argumen-argumenmu menjadi artikel yang padat akan informasi dan dapat bermanfaat bagi orang lain.
                              </p>
                              <p className="text-center">
                                <h3 className="text-dark"><q>Ubah bacotanmu menjadi informasi yang berguna</q></h3>
                              </p>
                              <br/>
                              <div className="text-left">
                                <Typed
                                  strings={[
                                    'Sumber Jelas',
                                    'Independen',
                                    'Transparan',
                                    'Apa adanya',
                                    ]}
                                    typeSpeed={80}
                                    backSpeed={80}
                                    loop
                                />                      
                              </div>
                          </CardBody>
                        </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>


        </div>
      <Footer />
      <br/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

