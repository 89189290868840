// Footer Compoent
import React from 'react'
import { Container } from 'reactstrap'
import LogoImg from '../images/icon.jpg' 

const Footer = () => (
		<div className="text-center site-footer">
			<Container>
				<img src={LogoImg} className="img-fluid"/>
			</Container>
			<h4>Ikuti Kami</h4>
			<Container className="social-share-links">
				<ul>
					<li><a href="https://www.facebook.com/Waingapu-Open-Mind-100230011672867/" target="_blank" rel="noopener noreferrer" className="facebook">
						<i className="fab fa-facebook-f fa-2x" />		
					</a></li>
					<li><a href="https://twitter.com/WaingapuM/" target="_blank" rel="noopener noreferrer" className="twitter">
						<i className="fab fa-twitter fa-2x" />				
					</a></li>
					<li><a href="https://www.instagram.com/waingapuopenmind/" target="_blank" rel="noopener noreferrer" className="instagram">
						<i className="fab fa-instagram fa-2x" />				
					</a></li> 
				</ul>	
				<br/>	
				<strong>Copyright &copy; {new Date().getFullYear()} Waingapu Open Mind. All Rights Reserved</strong>
			</Container>
		</div>
)

export default Footer

