// Footer Compoent
import React from 'react'
import { Jumbotron } from 'reactstrap';
import Typed from 'react-typed';

const JumbotronComp = ({ title, info }) => (
	<Jumbotron style={{backgroundColor: '#757575'}}>
		<h2 className="text-center">
	        <Typed
    	      strings={[
        	    `${title}`,
	            'Sumber Jelas',
	            'Independen',
	            'Transparan',
	            'Apa adanya',
           	  ]}
              typeSpeed={80}
              backSpeed={80}
              loop
        	/>                      
		</h2>
		<p className="lead">{info}</p>
	</Jumbotron>
)

export default JumbotronComp;
 